class SharedConstants {}

SharedConstants.RECAPTCHA_TEST_KEY = "6LfU2x4bAAAAADTuR20WQLgAtOVsLWfg-G3zTMua";
SharedConstants.RECAPTCHA_SITE_KEY = "6LfQ7x4bAAAAAPhrYPJsog3uRlWdE6tK_JgoWV1v";

SharedConstants.NEWSLETTER_PROJECT_AUTH = "YWxleGFuZGVyLmt1dHRpZ0Bob3Jpem9uLWFscGhhLmNvbTpHRnE1MjlPViRqaiY=";

SharedConstants.MAPBOX_ACCESS_TOKEN = "pk.eyJ1Ijoic2NobG9zc2VyZmUiLCJhIjoiY2twbW00b3huMDFndjJ2cGZsbXltbW03YyJ9.a-A6XTK78uGoHuH_TjTQVw";

export default SharedConstants;
