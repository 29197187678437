import React from "react"

import checkmark from '../components/assets/images/checkmark_white.png'
import Map from "../components/map"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Formular from "../components/formular"
import CollapsibleContent from "../components/collapsibleContent"
import kampagneImage from "../components/assets/images/FairWearLogo.png"
import galerie1 from "../components/assets/images/galerie/Foto1.png"
import galerie2 from "../components/assets/images/galerie/Foto2.png"
import galerie3 from "../components/assets/images/galerie/Foto3.png"
import topImage from "../components/assets/images/header/Schoeffel_Visuals.png"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faArrowUp, faUpload } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowDown, faArrowUp, faUpload)

const iFrame = <div className="col-sm-12 col-md-4">
  <div className="schoeffel-video-box">
    <iframe width="100%" src="https://www.youtube.com/embed/oOUfQUblr1U?autoplay=0&rel=0" frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen className="schoeffel-video"></iframe>
  </div>
</div>;

const empty = <div><p> </p></div>;

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <div className="top-area">
      <img src={topImage} className="top-image" alt="Header"/>
    </div>
    <div className="content-area">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <h3 className="text-center w-100 p-v-3">
              <h2 className="hashtag-text m-0 p-0">#SupportYourLocalSportfachhandel</h2><br/>
              Schöffel macht Dich zum Baumpaten!
            </h3>
            <p>
              Unterstütze die lokalen Sportgeschäfte und mach nebenbei was Gutes für die Umwelt. Die Zeit der Lockdowns bedroht viele Sportgeschäfte. Jetzt gilt es zusammen zu halten. Packen wir‘s gemeinsam an!
              <br/><br/>
              Kaufe im Sportladen um die Ecke ein Schöffel Produkt und wir setzen für Dich einen oben drauf: einen Baumsetzling. Als Dankeschön, dass Du den lokalen Sportfachhandel unterstützt, macht Dich Schöffel zum Paten für einen Baum in Deutschland, Österreich oder der Schweiz. 30.000 Bäume haben wir schon gepflanzt – und es geht weiter!
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 black-box p-3">
            <b className="mt-md-0 text-white">
              Wie das funktioniert? Ganz einfach:
            </b>
            <p> </p>
            <ol type="1" className="text-white">
              <li>
                <div className="flex-container-horizontal">
                  <p className="check-text text-white">Kaufe ein Schöffel Produkt im lokalen Sportfachhandel</p>
                  <img src={checkmark} className="img-checkmark grayscale" alt="checkmark"/>
                </div>
              </li>
              <li>
                <div className="flex-container-horizontal">
                  <p className="check-text text-white">Fotografiere oder scanne den Kassenbon für Dein Schöffel Produkt.</p>
                  <img src={checkmark} className="img-checkmark" alt="checkmark"/>
                </div>
              </li>
              <li>
                <div className="flex-container-horizontal">
                  <p className="linking-check-text text-white">Lade die Datei (als jpg, png oder pdf) ganz einfach <a href="#form" className="text-white"><b><u>in unser Formular</u></b></a> und schick es ab.</p>
                  <img src={checkmark} className="img-checkmark" alt="checkmark"/>
                </div>
              </li>
              <li>
                <div className="flex-container-horizontal">
                  <p className=" check-text text-white">Für jede teilnahmeberechtigte Einreichung pflanzt Schöffel einen Baum.</p>
                  <img src={checkmark} className="img-checkmark" alt="checkmark"/>
                </div>
              </li>
              <li>
                <div className="flex-container-horizontal">
                  <p className="check-text text-white">Du erhältst eine personalisierte Bestätigung und erfährst, wo wir in Deinem Namen den Baum gepflanzt haben.</p>
                  <img src={checkmark} className="img-checkmark" alt="checkmark"/>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-3 justify-content-md-center">
            <img src={galerie1} className="galerie-image" alt=""/>
            <p className="text-center">
              Kaufe im lokalen Sportfachhandel ein Schöffel Produkt.
            </p>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-3 justify-content-md-center">
            <img src={galerie2} className="galerie-image" alt=""/>
            <p className="text-center">
              Lade die Rechnung auf baum.schoeffel.com hoch.
            </p>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-3 justify-content-md-center">
            <img src={galerie3} className="galerie-image" alt=""/>
            <p className="text-center">
              Wir spenden in Deinem Namen einen Baum.
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 centered-content black-box">
            <a href="#form" className="text-white"><p><b>HIER MITMACHEN UND BAUMPATE WERDEN</b></p></a>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <h2 className="text-center w-100">
              Schöffel ist fair zur Natur und fair zu den Menschen
            </h2>
            <p>
              Für Schöffel bedeutet Nachhaltigkeit weit mehr als die Produktion von möglichst umweltverträglicher Sportbekleidung. Wir sind nachhaltig und verantwortungsvoll,
              <ul>
                <li>weil wir unsere langjährigen Partner im Sportfachhandel auch in schweren Zeiten unterstützen und somit einen Beitrag zu lebendigen Ortschaften leisten und Arbeitsplätze erhalten.</li>
                <li>weil wir langlebige Produkte entwickeln, auf die sich Outdoor-Fans auch in Extremsituationen verlassen können.</li>
                <li>weil wir unseren ökologischen Fußabdruck bei all unseren Aktivitäten so gering wie möglich halten.</li>
                <li>weil wir gemeinsam mit der Fair Wear Foundation dafür sorgen, dass bei unseren Produzenten faire und sichere Arbeitsbedingungen herrschen.</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 centered-content">
            <img src={kampagneImage} className="img-responsive" alt="Preise Überblick"/>
            <p className="text-center">
              Schöffel konnte in den letzten Jahren sechs Mal in Folge den Leader Status der Fair Wear Foundation erreichen. Schöffel Bekleidung ist nachhaltig und fair!
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <h2 className="text-center w-100">
              Wo haben wir im Namen von Schöffel Kunden schon Bäume gepflanzt?
            </h2>
            <p className="text-center">
              Schon über 30.000 Bäume im Namen von Schöffel-Kunden
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Map />
          </div>
        </div>
        <Formular/>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 justify-content-md-center">
            <h2 className="text-center w-100">
              Videos
            </h2>
            <div className="schoeffel-video-box">
              <iframe width="100%" src="https://www.youtube.com/embed/mjUsQqSG5bo" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen className="schoeffel-video"></iframe>
            </div>
          </div>
        </div>
        <CollapsibleContent/>
      </div>
    </div>
  </Layout>
)

export default IndexPage
