import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const contentData = {
  0: {
    title: "Welche Kassenbons sind teilnahmeberechtigt?",
    text: "Grundsätzlich zählt jeder Kassenbon, der folgende Kriterien erfüllt: @list@1.Der Kassenbon erreicht uns im Aktionszeitraum zwischen dem 11.06.2021 und dem 18.07.2021.2.Der Kassenbon stammt von einem stationären Sportfachhändler.3.Es wurde mindestens ein Schöffel-Produkt gekauft.4. Auf dem Kassenbon ist das Datum aufgeführt und lautet zwischen dem 11.06.2021 und dem 18.07.2021."
  },
  1: {
    title: "Gibt es einen Mindestwarenwert für die Aktion?",
    text: "Nein, jeder Kassenbon mit einem Schöffel-Produkt zählt."
  },
  2: {
    title: "Und wenn ein Kassenbon mehrere Schöffel-Produkte ausweist?",
    text: "Dann sagen wir hier schon mal im Namen des lokalen Sportfachhändler und von Schöffel: herzlichen Dank. Allerdings haben wir uns aus prozessualen Gründen für die einfache Regel „Ein Baum pro Kassenbon“ entschieden."
  },
  3: {
    title: "Muss ich den kompletten Kassenbon hochladen?",
    text: "Wenn Du möchtest, kannst Du andere Produkte oder natürlich auch Deine Zahlungsdaten schwärzen oder weglassen. Zur Teilnahmeberechtigung benötigen wir nur die Angaben zu Sportfachhändler, Schöffel-Produkt und Datum."
  },
  4: {
    title: "Was macht Ihr mit meinen Daten?",
    text: "Deine Daten sind bei uns in sicheren Händen. Wir verwenden sie ausschließlich zur Abwicklung dieser Aktion. Schließlich sollst Du ja eine personalisierte Bestätigung Deiner Baumpatenschaft erhalten. Alles Weitere findest Du @link@in unserer Datenschutzerklärung*/teilnahmebedingungen@link@."
  },
  5: {
    title: "Woher weiß ich, dass Schöffel tatsächlich Bäume pflanzt?",
    text: "Diese Aktion ist uns eine echte Herzensangelegenheit. Deshalb erhältst Du nicht nur eine personalisierte Nachricht, in der wir Dich über den Standort „Deines“ Baumes informieren. Sondern wir informieren auch regelmäßig mit Social Media Posts und Videos über den Stand der Aktion. Abonniere am besten unseren @link@Newsletter*https://www.schoeffel.com/de/de/newsletter@link@ oder folge uns auf @link@Instagram*https://www.instagram.com/schoeffel_official/@link@, @link@Facebook*https://www.facebook.com/schoeffel.de@link@ und @link@YouTube*https://www.youtube.com/schoeffelde@link@."
  },
  6: {
    title: "Wie stellt Schöffel den Erfolg der Baumpflanz-Aktion sicher?",
    text: "Schöffel hat bereits andere Aktionen initiiert, bei denen Tausende Bäume gepflanzt wurden. Zum Beispiel @link@bei unserer VAT-Aktion*https://www.schoeffel.com/de/de/vat@link@.  Damals wie jetzt gilt: Uns geht es nicht darum, irgendwelche Bäume irgendwo zu pflanzen. Deshalb arbeiten wir mit erfahrenen Partnern zusammen. Sie wissen genau, wo wir gemeinsam ein möglichst gutes Ergebnis für nachhaltig gesunde Wälder erzielen können."
  },
  7: {
    title: "Schöffel-Produkte kaufen nur um Bäume zu pflanzen – das hat doch nichts mit Nachhaltigkeit zu tun!?",
    text: "Vollkommen richtig! Auch wenn wir unsere Produkte so ressourcenschonend wie möglich herstellen (@link@hier erfährst Du mehr darüber*https://www.schoeffel.com/de/de/sustainability@link@) und überzeugt sind, dass unsere Sportartikel den Menschen lange Zeit viel Freude bereiten, wollen wir Dich nicht zum unüberlegten Einkauf überreden. Unsere Aktion richtet sich vor allem an diejenigen unter Euch, die Ihre Kaufentscheidung bereits getroffen oder den Kauf bereits getätigt haben. Danke, dass Ihr den stationären Sportfachhandel unterstützt und uns Euer Vertrauen schenkt!"
  },
  8: {
    title: "Wie finde ich ein Sportgeschäft mit Schöffel-Produkten?",
    text: "Das geht ganz einfach @link@mit unserem Storefinder.*https://www.schoeffel.com/de/de/storefinder@link@"
  },
  9: {
    title: "Ich habe weitere Fragen zur Aktion – an wen kann ich mich wenden?",
    text: "Schreibe bitte eine Mail an mail@schoeffel.com mit Betreff „Aktion Baumpatenschaft“. Wir versuchen, Dir so schnell wie möglich zu antworten."
  }
}

export default class collapsibleContent extends React.Component {
  state = {
    collapsed: [0,1,2,3,4,5,6,7,8,9]
  }

  boldTextVersion = (originalText, id, isCollapsed) => {
    if (isCollapsed) {
      return <div/>
    } else {
      if (originalText.indexOf("@list@") !== -1) {
        const splittedText = originalText.split("@list@")
        const textPart = splittedText[0];
        const listFirst = splittedText[1].substr(2, splittedText[1].indexOf("2.") - 2);
        const listSecond = splittedText[1].substr(splittedText[1].indexOf("2.") + 2, splittedText[1].indexOf("3.") - splittedText[1].indexOf("2.") - 2);
        const listThird = splittedText[1].substr(splittedText[1].indexOf("3.") + 2, splittedText[1].indexOf("4.") - splittedText[1].indexOf("3.") - 2);
        const listFourth = splittedText[1].substr(splittedText[1].indexOf("4.") + 2, splittedText[1].length - splittedText[1].indexOf("4.") - 2);
        return(
          <div className="flow-container-horizontal p-3 pb-0" key={id + "_text"}>
            <p className="display-inline" key={"text_"}>{textPart}</p>
            <ul>
              <li>{listFirst}</li>
              <li>{listSecond}</li>
              <li>{listThird}</li>
              <li>{listFourth}</li>
            </ul>
          </div>
        )
      } else {
        const splittedText = originalText.split("@link@")
        const text = []
        for (let i = 0; i < splittedText.length; i++) {
          if (i % 2 === 0) {
            text.push(<p className="display-inline" key={"text_" + i}>{splittedText[i] + " "}</p>)
          } else {
            const nextChar = splittedText[i + 1].substr(0, 1);
            const linkEnd = splittedText[i].length - 1;
            const linkStart = splittedText[i].indexOf("*");
            const link = splittedText[i].substr(linkStart + 1, linkEnd-linkStart);
            const textPart = splittedText[i].substr(0, linkStart);
            if ((nextChar === "." || nextChar === "-" || nextChar === ",")) {
              text.push(<p className="display-inline link-text" key={"text_" + i}><a className="link-text" href={link} target="_blank">{textPart}</a></p>)
            } else {
              text.push(<p className="display-inline" key={"text_" + i}><a className="link-text" href={link} target="_blank">{textPart}</a> </p>)
            }
          }
        }
        return <div className="flow-container-horizontal p-3 pb-0" key={id + "_text"}>{text}</div>
      }
    }
  }

  boldTitleVersion = (originalText, id, isCollapsed, index) => {
    const splittedText = originalText.split("@bold@")
    const text = []
    for (let i = 0; i < splittedText.length; i++) {
      if (i % 2 === 0) {
        text.push(<b>{splittedText[i]}</b>)
      } else {
        const nextChar = splittedText[i + 1].substr(0, 1)
          if ((nextChar === "." || nextChar === "-")) {
            text.push(<b>{splittedText[i]}</b>)
          } else {
            text.push(<b>{splittedText[i] + " "}</b>)
          }
      }
    }
    return <div className="flow-container-horizontal p-3 clickable" key={id + "_title"} onClick={() => {
      if (isCollapsed) {
        let collapsed = this.state.collapsed.slice();
        collapsed.splice(collapsed.indexOf(index),1)
        this.setState({ collapsed: collapsed })
      } else {
        let collapsed = this.state.collapsed.slice();
        collapsed.push(index);
        this.setState({ collapsed: collapsed })
      }
    }}>
      {text}
      <FontAwesomeIcon icon={isCollapsed ? "arrow-down" : "arrow-up"} className="collapsible-icon" />
    </div>
  }

  renderPriceImage = (image, id, link) => {
    if (link) {
      return (<a href={link} target='_blank' rel='noopener noreferrer'><img src={image} className="img-responsive" alt="dummy"
                                                                    key={id + "_image"}/></a>)
    } else {
      return (<img src={image} className="img-responsive" alt="content" key={id + "_image"}/>)
    }
  }

  renderPrices = (i) => {
    let id = "id_" + i
    let isCollapsed = this.state.collapsed.includes(i);
    let contentDataText = this.boldTextVersion(contentData[i].text, id, isCollapsed, i)
    let contentDataTitle = this.boldTitleVersion(contentData[i].title, id, isCollapsed, i)
    let image = <div/>
    if (contentData[i].image && contentData[i].link) {
      return (
        <div className="row" key={id + "_row"}>
          <div className="col-sm-12 col-md-4" key={id + "_imageContainer"}>
            {this.renderPriceImage(contentData[i].image, id, contentData[i].link)}
          </div>
          <div className="col-sm-12 col-md-8 pl-md-2" key={id + "_priceContainer"}>
            {contentDataTitle}
            <div className="content-content" key={id + "_priceTextContainer"}>
              {contentDataText}
              <a href="#form"><p className="mb-2"><b>Jetzt hier für diesen Danke Moment bewerben!</b></p></a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row justify-content-md-center" key={id + "_row"}>
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8" key={id + "_priceContainer"}>
            {contentDataTitle}
            <div className="content-content" key={id + "_priceTextContainer"}>
              {contentDataText}
            </div>
          </div>
        </div>
      )
    }
  }

  render = () => {
    let prices = []
    for (let i = 0; i < Object.keys(contentData).length; i++) {
      prices.push(this.renderPrices(i))
    }

    return (
      <div>
        <h2 className="text-center w-100">
          FAQ
        </h2>
        {prices}
      </div>
    )
  }

}
