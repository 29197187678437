import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import bbox from "@turf/bbox"
import { multiPoint } from "@turf/helpers"
import SharedConstants from "../constants/SharedConstants"
import Markers from "./markers"

const mapContainerStyle = {
  width: "100%",
  height: "360px",
}

const places = [
  {
    name: "Oberammergau",
    longitude: 11.066370,
    latitude: 47.593033,
  },
  {
    name: "Schliersee",
    longitude: 11.861002,
    latitude: 47.720964,
  },
  {
    name: "Spitzingsee",
    longitude: 11.885137,
    latitude: 47.664384,
  },
]

const Map = () => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: SharedConstants.MAPBOX_ACCESS_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      // Empire State Building [lng, lat]
      center: [11.5655, 47.5986],
      zoom: 6,
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    setMap(map)

    return () => map.remove()
  }, [])

  useEffect(() => {
    if (!map) return

    if (places.length !== 0) {
      const coords = []
      places.forEach(place => {
        coords.push([place.longitude, place.latitude])
      })
      const feature = multiPoint(coords)
      const box = bbox(feature)

      map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]],
        ],
        {
          padding: 20,
          zoom: 8,
          duration: 2000,
        }
      )
    } else {
      map.easeTo({
        center: [-73.9856, 40.7497],
        zoom: 10,
        duration: 2000,
      })
    }
  }, [map])

  return <div ref={mapContainerRef} style={mapContainerStyle}>
    {places && map && <Markers map={map} places={places} />}
  </div>
}

export default Map
