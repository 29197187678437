import React from "react"
import ReCAPTCHA from "react-google-recaptcha";
import SharedConstants from "../constants/SharedConstants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default class formular extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    base64Image: "",
    fileName: "",
    disabled: true,
    legal: false,
    showDataHint: false,
    showEmailHint: false,
    showEmailAlreadyInUse: false,
    showLegalHint: false,
    hasClickedSubmit: false,
    submitted: false,
    sending: false,
    doubleOptIn: false
  };
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if (name === "email") {
      this.setState({ showEmailHint: false, showEmailAlreadyInUse: false})
    }
    if (name === "legal") {
      this.setState({ showLegalHint: false})
    }
    if (name === "doubleOptIn") {
      const { checked } = event.target;
      console.log("checked", checked)
      this.setState({
        doubleOptIn: checked,
        showDataHint: false
      })
    } else {
      this.setState({
        [name]: value,
        showDataHint: false
      })
    }
  }

  validateEmail = (email) => {
    const mailtest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailtest.test(email);
  };

  hasEnteredData = () => {
    this.setState({ hasClickedSubmit: true })
    if (this.state.firstName === "" || this.state.lastName === "" ||
      this.state.email === "" || this.state.base64Image === "") {
      this.setState({ showDataHint: true })
    } else if (!this.state.legal) {
      this.setState({ showLegalHint: true })
    } else if (this.validateEmail(this.state.email)) {
      this.setState({sending: true});
      let user = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        doubleOptIn: this.state.doubleOptIn,
        base64Image: this.state.base64Image,
        verified: false,
        creationDate: Date.now()
      }
        fetch('https://europe-west3-schoeffel-green-marketing-2021.cloudfunctions.net/safeParticipantData', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: user,
            token: "KAFH46jHFAJF857jydfHASGD876DFKAGH8457n457vJFHG746n"
          })
        }).then(r => {
          if (r.status === 405) {
            let error = {}
            error.code = 405;
            error.message = "Diese Email ist bereits registriert!"
            throw(error)
          };
          this.setState({
            submitted: true,
            sending: false
          });
          setTimeout(() => {
            window.fbq('trackCustom', 'Submitted');
          }, 0);
        }).catch(error => {
          this.setState({sending: false});
          if (error.code === 405) {
            this.setState({showEmailAlreadyInUse: true})
            console.log(error.message);
          } else {
            console.log(error)
          }
        })
    } else {
      this.setState({ showEmailHint: true })
    }
  }

  handleCaptchaResponse = () => {
    this.setState({disabled: false})
  }

  handleFileInput = (e) => {
    let file = e.target.files[0];

    if (file) {
      let fileName = file.name;
      const reader = new FileReader();
      reader.onload = this._handelReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
      this.setState({fileName: fileName})
    }
  }

  _handelReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.setState({base64Image: btoa(binaryString)})
  }


  render() {
    const handleClick = event => {
      this.hiddenFileInput.current.click();
    };
    let billButtonText = this.state.fileName !== "" ? this.state.fileName + " Hochgeladen" : "HIER RECHNUNG HOCHLADEN";
    let dataHint = <div/>;
    if (this.state.showDataHint) {
      dataHint = <div><p className="hint-text">Bitte fülle alle Datenfelder aus.</p></div>
    } else if (this.state.showEmailHint) {
      dataHint = <div><p className="hint-text">Bitte überprüfe die eingegebene E-Mail Adresse.</p></div>
    } else if (!this.state.legal && this.state.hasClickedSubmit) {
      dataHint = <div><p className="hint-text">Bitte aktzeptiere die Teilnahmebedingungen.</p></div>
    } else if (this.state.showEmailAlreadyInUse) {
      dataHint = <div><p className="hint-text">Diese Email ist bereits registriert!</p></div>
    }

    if (this.state.submitted) {
      return (
        <div className="row" id="form">
          <div>
            <h2>
              Danke für Deine Teilnahme!
            </h2>
            </div>
        </div>
      )
    } else {
      return (
        <div className="row" id="form">
          <div className="col-sm-12 col-md-12">
            <h2 className="text-center w-100">
              Jetzt Kassenbon einreichen & Baumpate werden
            </h2>
            <div>
              <div className="form-group">
                <label htmlFor="inputFirstName">Vorname *</label>
                <input type="text" className="form-control" id="inputFirstName" name="firstName"
                       aria-describedby="emailHelp" placeholder="Dein Vorname" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputLastName">Nachname *</label>
                <input type="text" className="form-control" id="inputLastName" name="lastName"
                       aria-describedby="emailHelp" placeholder="Dein Nachname" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">E-Mail *</label>
                <input type="email" className="form-control" id="inputEmail" name="email"
                       aria-describedby="emailHelp" placeholder="Deine E-Mail Adresse" required
                       onChange={this.handleInputChange}/>
                <small id="emailHelp" className="form-text text-muted">Wir teilen Deine
                  E-Mail Adresse mit niemandem.</small>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Rechnung *</label>
                <button onClick={handleClick} className="btn btn-light mt-2 flex-row "><FontAwesomeIcon icon={"upload"} className="upload-icon" />{billButtonText}</button>
                <input type="file" name="image"
                       id="file"
                       ref={this.hiddenFileInput}
                       onChange={this.handleFileInput}
                       style={{display:'none'}}
                       accept=".jpeg, .png, .jpg, .pdf"
                       required
                />
                <small id="emailHelp" className="form-text text-muted">Reiche Deine Rechnung ein.</small>
              </div>
              <div>
                <label htmlFor="inputLegal" className="legal-label justify-content-center">
                  <input type="checkbox" id="inputLegal" name="legal"
                         aria-describedby="emailHelp" required
                         onChange={this.handleInputChange} className="legal-checkbox mr-2"/>
                  Die <a href="https://www.schoeffel.com/de/de/datenschutz" className="link-text" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> dieser Website habe ich zur Kenntnis genommen und die <a href="/teilnahmebedingungen" className="link-text" target="_blank">Teilnahmebedingungen</a> der Aktion akzeptiert.
                </label>
              </div>
              <div>
                <label htmlFor="inputDoubleOptIn" className="legal-label justify-content-center">
                  <input type="checkbox" id="inputDoubleOptIn" name="doubleOptIn"
                         aria-describedby="emailHelp"
                         onChange={this.handleInputChange} className="legal-checkbox mr-2"/>
                  Ich bin damit einverstanden, dass mich Schöffel im Rahmen der Aktion per E-Mail kontaktiert, um mich über meine erfolgreiche Baumspende zu informieren.
                </label>
              </div>
              {dataHint}
              <div>
                <ReCAPTCHA
                  sitekey={SharedConstants.RECAPTCHA_SITE_KEY}
                  render={"explicit"}
                  onChange={this.handleCaptchaResponse}
                  className="centered-recap"
                />
              </div>
              <button className="btn btn-primary mt-2" onClick={this.hasEnteredData} disabled={this.state.disabled}>{ this.state.sending ? "Wird versendet..." : "Absenden"}</button>
            </div>
          </div>
        </div>
      )
    }
  }
}
